
import {
    computed, defineComponent, PropType, ref, watch
} from 'vue';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import { initForm, getFormRules, initList } from '@/views/pm/old-community/staff-auth/module/set';
import initCheck from '@/methods/relay-choose';
import { access } from '@/data';
import { list } from '@/components/common/list';
import Notice from '@/util/notice.base';
import { format } from '@/methods/date';
import { staffAuthApi } from '@/api';
import DeviceIcon from '@/components/view/common/device-icon/index.vue';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        list,
        DeviceIcon
    },
    props: {
        opera: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        }
    },
    setup(props) {
        const breadHeaderLabel = {
            add: WordList.DeliveryAddRCard,
            edit: WordList.DeliveryEditRCard
        };
        const breadHeaders = [{
            label: WordList.DeliveryStaffAuth,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldStaffAuth}`
        }, {
            label: breadHeaderLabel[props.opera]
        }];

        const {
            formRef,
            formData,
            repeatOptions
        } = initForm(props.opera);
            // 监听切换模式，加载不同rules
        const rules = ref(getFormRules(formData.value.SchedulerType));
        watch(computed(() => formData.value.SchedulerType), () => {
            rules.value = getFormRules(formData.value.SchedulerType);
        });

        // list数据
        const {
            headers,
            requestData,
            update
        } = initList();

        // relay
        const {
            allCheck, checkArray, dealList, choose, getCommit, changeAllCheck, setCheck
        } = initCheck();
        if (props.opera === 'edit') {
            setCheck(formData.value.Devices, update);
        }

        // 提交
        const submit = () => {
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox(
                    'alert',
                    WordList.RuleMacChoose,
                    WordList.PersonuserInfo,
                    'info'
                )(() => false);
                return false;
            }
            const url = {
                add: staffAuthApi.addStaffRfCard,
                edit: staffAuthApi.editStaffRfCard
            };
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const param = { ...formData.value };
                    if (param.SchedulerType === access.weekly && typeof param.DateFlag !== 'string') {
                        param.DateFlag = param.DateFlag.join(';');
                    }
                    param.BeginTime = format(param.BeginTime, 'YYYY-MM-DD HH:mm:ss');
                    param.EndTime = format(param.EndTime, 'YYYY-MM-DD HH:mm:ss');
                    param.StartTime = format(param.StartTime, 'HH:mm:ss');
                    param.StopTime = format(param.StopTime, 'HH:mm:ss');
                    url[props.opera]({ ...param, MAC: devices }, () => {
                        if (localStorage.getItem('modifyKey') === '1') {
                            localStorage.removeItem('modifyKey');
                            router.back();
                        }
                        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldStaffAuth}`);
                    });
                }
            });
            return true;
        };

        return {
            breadHeaders,
            formRef,
            formData,
            repeatOptions,
            headers,
            requestData,
            update,
            allCheck,
            checkArray,
            dealList,
            choose,
            getCommit,
            changeAllCheck,
            setCheck,
            access,
            submit,
            rules
        };
    }
});
